<template>
    <div class="container">
        <div class="col-wrap header">
            <div class="bg  bg-animation2"></div>
            <div class="wrap-content">
                <Nav></Nav>
                <div class="content">
                    <h1>ITRAX CUSTOMER</h1>
                    <h3>行业趋势分析、数据分析、精准策略创意、专业服务执行</h3>
                    <p>
                        Industry trend analysis, data analysis, precise and creative strategy and professional service performance
                    </p>
                </div>
            </div>
        </div>
        <div class="col-wrap customer">
            <div class="wrap-content">
                <div class="col-title">
                    <h1>汽车行业</h1>
                    <div>Automobile</div>
                </div>

                <img v-if="customer" :src="resourceBaseUrl + customer.pcAuto + '?x-oss-process=image/resize,w_1280'" />
            </div>
            <div class="wrap-content">
                <div class="col-title">
                    <h1>快消/家具</h1>
                    <div>FMCG and Finance</div>
                </div>

                <img v-if="customer" :src="resourceBaseUrl + customer.pcFmcg + '?x-oss-process=image/resize,w_1280'" />
            </div>
            <div class="wrap-content">
                <div class="col-title">
                    <h1>金融/保险</h1>
                    <div>Financial and Insurance</div>
                </div>

                <img v-if="customer" :src="resourceBaseUrl + customer.pcFinancial + '?x-oss-process=image/resize,w_1280'" />
            </div>
            <div class="wrap-content">
                <div class="col-title">
                    <h1>3C/IT</h1>
                    <div>3C/IT</div>
                </div>

                <img v-if="customer" :src="resourceBaseUrl + customer.pcIt + '?x-oss-process=image/resize,w_1280'" />
            </div>
        </div>
        <div class="col-wrap">
            <div class="content">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
export default {
    name: "pc-customer",
    components: { Nav, Footer },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            customer: null,
        };
    },
    mounted() {
        this.loadCustomer();
    },
    methods: {
        loadCustomer() {
            this.$http.get("/api/customer/config").then((res) => {
                this.customer = res.data;
            });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/pc.css");
.col-title {
    margin-top: 100px;
}
.customer {
    padding-bottom: 80px;
    background: url("~@/assets/pc/customer/bg.jpg") no-repeat center;
    background-size: 100% 100%;
}

.header .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header .wrap-content .content {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1440px) {
    .header .bg {
        background: url("~@/assets/pc/customer/banner-bg.jpg") no-repeat center;
        height: 500px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .customer img {
        margin-left: 25px;
        max-width: 870px;
    }
}

@media only screen and (min-width: 1441px) {
    .header .bg {
        background: url("~@/assets/pc/customer/banner-bg.jpg") center bottom;
        height: 760px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .customer img {
        margin-left: 64px;
    }
}
</style>
